// extracted by mini-css-extract-plugin
export var column = "BlogPostsOptions__column__xlmTi";
export var flex = "BlogPostsOptions__flex__V_7FV";
export var flexColumn = "BlogPostsOptions__flexColumn__Xw4BW";
export var gap1 = "BlogPostsOptions__gap1__qtpjr";
export var gap2 = "BlogPostsOptions__gap2__NGiER";
export var gap3 = "BlogPostsOptions__gap3__JcXYg";
export var gap4 = "BlogPostsOptions__gap4__lanlm";
export var gap5 = "BlogPostsOptions__gap5__aEpxb";
export var popoverBody = "BlogPostsOptions__popoverBody__q6Ld_";
export var popoverBodyLink = "BlogPostsOptions__popoverBodyLink__Bw6d6";
export var popoverBodyLinkIcon = "BlogPostsOptions__popoverBodyLinkIcon__sYyJz";
export var row = "BlogPostsOptions__row__LGQI6";